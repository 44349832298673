$red            : #B03060;
$orange         : #FE9A76;
$yellow         : #FFD700;
$olive          : #32CD32;
$green          : #016936;
$teal           : #008080;
$blue           : #0E6EB8;
$violet         : #EE82EE;
$purple         : #B413EC;
$pink           : #FF1493;
$brown          : #A52A2A;
$grey           : #A0A0A0;
$black          : #000000;
$primaryColor   : $blue;
$secondaryColor : $grey;

@import "post";
@import "page";

body{
    background: url("/assets/Bear%20Dukes%20Luxembourg%20-%20faded.png") no-repeat -150px -150px;
    background-attachment: fixed;
    filter:transparent(0.7);
}
