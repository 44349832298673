#post{
  .content {
    width: 90%;
    margin: auto auto;
    top: -50px;
  }
  &>img{
    z-index: -3;
  }
  .meta{
    margin-bottom: 0;
    text-align: center;
    color: $grey;
  }
  .center.aligned.header{
    margin-top:0;
  }
}

@media only screen and (max-width: 991px){
  #post{
    .content{
      width: 95%;
    }
  }
}
